<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card title="Tambah Baru">
        <template slot="action">
          <c-button :to="{name:'brands'}" variant="outline-primary">Kembali</c-button>
          <c-button :loading="isloading" @click="$refs.form.onSubmit()" type="submit">Simpan</c-button>
        </template>
        <c-form ref="form" @submit="confirmSubmit">
          <form-input list="code-list" label="Kode" v-model="formData.code" :rules="{required: true}"></form-input>
          <datalist id="code-list">
            <option v-for="(value,key) in code_datasets" :key="key">{{value.code}}</option>
          </datalist>
          <form-input label="Nama" v-model="formData.name" :rules="{required: true}"></form-input>
          <form-checkbox v-model="formData.is_active" label="Aktif"></form-checkbox>
          <button type="submit" hidden></button>
        </c-form>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import FormInput from '@/utils/components/FormInput.vue'
import CForm from '@/utils/components/CForm.vue'
import CButton from '@/utils/components/CButton.vue'
import FormCheckbox from '@/utils/components/FormCheckbox.vue'

export default {
  components: { CCard, FormInput, CForm, CButton, FormCheckbox },
  data(){
    const formData = {
      code:'',
      name:'',
      is_active: true
    }
    return {
      formData,
      isloading: false,
      code_datasets:[]
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    submitForm(){
      const vm = this
      vm.isloading = true
      const formData = vm.formData
      formData.is_active = formData.is_active ? 1 : 0
      this.$http.post(`v1/brands/create`,formData).then(() => {
        vm.notify(`Data Berhasil Disimpan!`)
        vm.$router.push({name:'brands'})
        vm.isloading = false
      }).catch((error) => {
        vm.isloading = false
        vm.handleError(error)
      })
    },
    async getDatasets(){
      const { data } = await this.$http.get(`v1/brands/code`)
      this.code_datasets = data
    }
  },
  created(){
    this.getDatasets()
  }
}
</script>

<style>

</style>